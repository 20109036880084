import React from 'react'
import cx from 'classnames'

import { makeStyles } from '@material-ui/core/styles'
import { Box, Button, Container, Grid, Typography } from '@material-ui/core'

import { container } from '../sharedStyles'
import HeroTitle from '../components/HeroTitle'
import FeatureBox from '../siteblocks/FeatureBox'
import avatar1 from '../images/avatar-1.png'
import avatar2 from '../images/avatar-2.png'
import myTasksScreenshot from '../images/homepage-my-tasks-screenshot.png'
import LearnMoreSection from '../components/LearnMoreSection'
import Layout from '../components/Layout'
//https://images.unsplash.com/photo-1517059224940-d4af9eec41b7?ixlib=rb-1.2.1&auto=format&fit=crop&w=1467&q=80

const AnchorButton = ({ children, ...other }) => {
	return (
		<Button
			color={'primary'}
			component={'a'}
			disableElevation
			variant={'contained'}
			{...other}
		>
			{children}
		</Button>
	)
}

const useStyles = makeStyles(
	theme => ({
		root: { flexGrow: 1 },
		mainScreenshot: {
			display: 'flex',
			justifyContent: 'center',
			marginTop: theme.spacing(8),
			position: 'relative',
			alignItems: 'center',
		},
		container: container(theme),
		screenshot: {
			boxShadow: theme.shadows[20],
			borderRadius: 2,
			display: 'block',
			transition: theme.transitions.create(),
			width: '90%',
			'&:hover': {
				boxShadow: theme.shadows[24],
				transform: 'scale( 1.01 )',
			},
			[theme.breakpoints.up('lg')]: {
				borderRadius: 8,
				width: '70%',
			},
			[theme.breakpoints.only('md')]: {
				borderRadius: 6,
				width: '70%',
			},
			[theme.breakpoints.only('sm')]: {
				borderRadius: 4,
				width: '80%',
			},
		},
		section: theme.mixins.gutters({
			paddingBottom: theme.spacing(10),
			paddingTop: theme.spacing(10),
		}),
		secondaryBackground: {
			backgroundColor: theme.palette.secondary.main,
			color: theme.palette.secondary.contrastText,
		},
		mutedBackground: {
			backgroundColor: theme.palette.background.emphasis,
		},
		content: {
			padding: theme.spacing(2),

			[theme.breakpoints.up('xl')]: {
				padding: theme.spacing(12),
			},
			[theme.breakpoints.only('lg')]: {
				padding: theme.spacing(9),
			},
			[theme.breakpoints.only('md')]: {
				padding: theme.spacing(6),
			},
			[theme.breakpoints.only('sm')]: {
				padding: theme.spacing(4),
			},
		},
		leftContent: {},
		rightContent: {},
		paddedCell: { padding: theme.spacing(2) },
		image: {
			borderRadius: 2,
			maxWidth: '100%',
			alignSelf: 'center',
			[theme.breakpoints.down('sm')]: {
				maxWidth: '75%',
			},
		},
		imageMarginBottom: {
			marginBottom: theme.spacing(2),
			maxWidth: '100%',
		},
		avatar: {
			borderRadius: 100,
		},
		heading: {
			[theme.breakpoints.down('sm')]: {
				fontSize: '2rem',
			},
		},
	}),
	{ name: 'FeaturesPage' }
)

const FeaturesPage = props => {
	const classes = useStyles()

	return (
		<Layout>
			<Container>
				<section className={classes.section}>
					<HeroTitle title={'Fewer apps. Less complexity.'} />
					<Typography align={'center'} gutterBottom variant={'h5'}>
						Tyto combines the most crucial elements of collaboration
						all into one place, making it easier for teams to
						communicate, collaborate and focus.
					</Typography>

					<div className={classes.mainScreenshot}>
						<img
							src={myTasksScreenshot}
							className={classes.screenshot}
							alt={'Screenshot'}
						/>
					</div>
				</section>
			</Container>

			<FeatureBox
				overline={'ALL-IN-ONE'}
				heading={'Video'}
				body={
					'Call teammates directly or host group chats— via computer or mobile phone— all in one easy place.'
				}
				action={
					<AnchorButton href={'/signup'}>
						See It In Action
					</AnchorButton>
				}
				imageUrl={
					'https://images.unsplash.com/photo-1588873281272-14886ba1f737?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=925&q=80'
				}
				reverse
				color={'#f5f5f5'}
			/>

			<FeatureBox
				overline={'ALL-IN-ONE'}
				heading={'Chat'}
				body={
					"Tyto's built-in chat allows you to communicate with teammates without jumping from system to system. And with detailed task visibility, it means you'll interrupt them less often."
				}
				action={
					<AnchorButton href={'/signup'}>
						See It In Action
					</AnchorButton>
				}
				imageUrl={
					'https://images.unsplash.com/photo-1488998287214-1e668a8e0dc4?ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'
				}
			/>

			<FeatureBox
				overline={'ALL-IN-ONE'}
				heading={'Tasks'}
				body={
					"Improve your own productivity as well as your team performance using Tyto's powerful tasking engine— built to handle the complexities of real life."
				}
				action={
					<AnchorButton href={'/signup'}>
						See It In Action
					</AnchorButton>
				}
				imageUrl={
					'https://images.unsplash.com/photo-1420310414923-bf3651a89816?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80'
				}
				reverse
				color={'#f5f5f5'}
			/>

			<FeatureBox
				overline={'ALL-IN-ONE'}
				heading={'Projects'}
				body={
					'Manage multiple projects and team members all from your computer or mobile phone. Tyto makes teams connect and clients happier.'
				}
				action={
					<AnchorButton href={'/signup'}>
						See It In Action
					</AnchorButton>
				}
				imageUrl={
					'https://images.unsplash.com/photo-1555436169-20e93ea9a7ff?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80'
				}
				color={'#f5f5f5'}
			/>

			<section
				className={cx(classes.section, classes.secondaryBackground)}
			>
				<Container>
					<Box mb={6}>
						<Typography
							align={'center'}
							color={'inherit'}
							variant={'h4'}
						>
							Kind Words From Early Adopters
						</Typography>
					</Box>

					<Grid container spacing={6}>
						<Grid item sm={6}>
							<Grid
								container
								alignItems={'center'}
								direction={'column'}
								spacing={2}
							>
								<Grid item>
									<Typography
										align={'center'}
										color={'inherit'}
										variant={'h6'}
									>
										"I've been through a ton of productivity
										apps for my team, never getting the
										results I was looking for. After just 2
										days with Tyto, I find myself eager to
										start my workday."
									</Typography>
								</Grid>
								<Grid item>
									<img
										className={classes.avatar}
										alt={'quote avatar'}
										src={avatar1}
									/>
								</Grid>
								<Grid item>
									<Typography
										color={'inherit'}
										variant={'h6'}
									>
										<em>— Pavel Popovichenko</em>
									</Typography>
								</Grid>
							</Grid>
						</Grid>

						<Grid item sm={6}>
							<Grid
								container
								alignItems={'center'}
								direction={'column'}
								spacing={2}
							>
								<Grid item>
									<Typography
										align={'center'}
										color={'inherit'}
										variant={'h6'}
									>
										"The score feature is awesome. Everyone
										here at our office has really had fun
										with it so far and each day feels like a
										race to see who can grind through the
										most tasks."
									</Typography>
								</Grid>
								<Grid item>
									<img
										className={classes.avatar}
										alt={'quote avatar'}
										src={avatar2}
									/>
								</Grid>
								<Grid item>
									<Typography
										color={'inherit'}
										variant={'h6'}
									>
										<em>— Gregg Richardson</em>
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</section>

			<LearnMoreSection classes={classes} />

			<section
				className={cx(classes.section, classes.secondaryBackground)}
			>
				<Container>
					<Box mb={4}>
						<Typography
							align={'center'}
							color={'inherit'}
							paragraph
							variant={'h4'}
						>
							Get Your Team On The Same Page
						</Typography>
					</Box>
					<Box align={'center'}>
						<Button
							color={'primary'}
							href={'/signup'}
							size={'large'}
							variant={'contained'}
						>
							Sign Up Today
						</Button>
					</Box>
				</Container>
			</section>
		</Layout>
	)
}

export default FeaturesPage
